import React, { useEffect, useState } from 'react'

import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Button } from '@atoms/buttons'
import { LayoutRow, LayoutCol, Card } from '@atoms/layout'
import { useEvents } from '@contexts/GTMProvider'
import { CartFragment, useSetShippingMethodsOnCartMutation, CheckoutFragment, useSetShippingAddressesOnCartMutation } from '@hooks/api/index'
import { CheckoutSummary } from '@molecules/store/CheckoutSummary'
import { Addresses, DisplayTypeEnum } from '@organisms/user/Addresses'
import { useSimpleToasts } from '@simple/toasts'
import { DeliveryStepEnum, ShippingActionEnum } from '@uctypes/api/globalTypes'

import { DeliveryMethods } from './DeliveryMethods'
import { PargoModal } from './PargoModal'
import { PargoLocation } from './PargoSelect'

const FixedMobile = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 500;
`

const FixedDesktop = styled.div`
  position: fixed;
  width: inherit;
`

export interface DeliveryProps {
  checkout: CheckoutFragment
  cart?: CartFragment
  loading?: boolean
}

interface DeliveryState {
  showPargoModal: boolean
  addressDisplayType: DisplayTypeEnum
}

const DEFAULT_STATE: DeliveryState = {
  showPargoModal: false,
  addressDisplayType: DisplayTypeEnum.LIST

}

export function Delivery({ checkout, cart }: DeliveryProps): JSX.Element {

  const [state, setState] = useState<DeliveryState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const [setShippingAddressesOnCart, { loading: setShippingAddressesLoading }] = useSetShippingAddressesOnCartMutation()
  const [setShippingMethodOnCart, { loading: setShippingMethodLoading }] = useSetShippingMethodsOnCartMutation()
  const [isUpdateingItems, setIsUpdatingItems] = useState<boolean>(false)
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const navigate = useNavigate()
  const events = useEvents()

  const setAddressDisplayType = (type: DisplayTypeEnum) => {
    setState(prevState => update(prevState, {
      addressDisplayType: {
        $set: type
      }
    }))
  }

  const _handleNext = async (): Promise<void> => {
    try {
      if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_ADDRESS) {
        await setShippingAddressesOnCart({
          variables: {
            input: {
              cartId: cart.id,
              shippingAddresses: [{
                customerAddressId: checkout.deliveryInfo.deliveryAddressId,
              }],
            },
          },
        })
        CheckoutPlugin.shared().nextDeliveryStep()
      } else if (checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_METHOD) {
        if (checkout.deliveryInfo.requiresPargoLocation && !checkout.deliveryInfo.pargoLocation) {
          setState((prevState) => update(prevState, {
            showPargoModal: {
              $set: true,
            },
          }))
        } else {
          const carrierCode = checkout.deliveryInfo.carrierCode
          const methodCode = checkout.deliveryInfo.deliveryMethod
          await setShippingMethodOnCart({
            variables: {
              input: {
                cartId: cart.id,
                shippingMethods: [{
                  rateUid: checkout.deliveryInfo.rateUid,
                  carrierCode,
                  methodCode,
                  shippingAction: checkout.deliveryInfo.deliveryAction ? checkout.deliveryInfo.deliveryAction : ShippingActionEnum.NORMAL,
                  pargoPointCode: checkout.deliveryInfo.requiresPargoLocation ? checkout.deliveryInfo?.pargoLocation?.pargoPointCode : undefined,
                }],
              },
            },
          })
          const shippingOption = cart?.shippingAddresses?.[0]?.availableShippingMethods?.find((ava) => ava.carrierCode === carrierCode && ava.methodCode === methodCode)
          if (shippingOption) {
            events.hasAddedShippingMethod(cart, `${shippingOption.carrierTitle} - ${shippingOption.methodTitle}`)
          }
          if (CheckoutPlugin.shared().shouldRemoveProblemItemsFromCart(cart)) {
            setIsUpdatingItems(true)
            await CheckoutPlugin.shared().removeProblemItemsFromCart(cart, client)
            setIsUpdatingItems(false)
          }
          CheckoutPlugin.shared().nextDeliveryStep()
          navigate('/checkout/payment')
        }

      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleAddressSelected = (deliveryAddressId: number): void => {
    CheckoutPlugin.shared().setDeliveryAddress({ deliveryAddressId })
  }

  const _handleDeliveryMethodSelected = (deliveryMethod: string, rateUid: string, carrierCode: string): void => {
    CheckoutPlugin.shared().setDeliveryMethod({ deliveryMethod, rateUid, carrierCode })
  }

  const _handlePargoSelect = (pargoLocation: PargoLocation): void => {
    CheckoutPlugin.shared().setPargoLocation({ pargoLocation: { ...pargoLocation, __typename: 'PargoLocation' } })
    setState((prevState) => update(prevState, {
      showPargoModal: {
        $set: false,
      },
    }))
  }

  const _handlePargoCancel = (): void => {
    setState((prevState) => update(prevState, {
      showPargoModal: {
        $set: false,
      },
    }))
  }

  const _handlePargoLocationChange = (): void => {
    setState((prevState) => update(prevState, {
      showPargoModal: {
        $set: true,
      },
    }))
  }

  const loading = setShippingAddressesLoading || setShippingMethodLoading
  const hasProducts = cart?.items?.length > 0
  const showContinueButton = state.addressDisplayType === DisplayTypeEnum.LIST &&
    checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_ADDRESS


  return (
    <>
      <PargoModal
        cart={cart}
        checkout={checkout}
        onSelect={_handlePargoSelect}
        onCancel={_handlePargoCancel}
        open={state.showPargoModal} />
      <LayoutRow>
        <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 8 }}>
          <Choose>
            <When condition={checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_ADDRESS}>
              <Addresses 
                selected={checkout.deliveryInfo?.deliveryAddressId} 
                onSelect={_handleAddressSelected} 
                displayType={state.addressDisplayType}
                setDisplayType={setAddressDisplayType}
                forceAdd />
            </When>
            <When condition={checkout.deliveryInfo.step === DeliveryStepEnum.DELIVERY_METHOD}>
              <DeliveryMethods
                selected={checkout.deliveryInfo?.rateUid}
                onSelect={_handleDeliveryMethodSelected}
                onPargoLocationChange={_handlePargoLocationChange}
                isUpdateingItems={isUpdateingItems} />
            </When>
          </Choose>
        </LayoutCol>
        <LayoutCol span={{ mobile: 0, tablet: 0, desktop: 4 }}>
          <If condition={!!cart}>
            <FixedDesktop>
              <CheckoutSummary
                loading={loading}
                cart={cart}
                checkout={checkout}
                onNext={_handleNext} />
            </FixedDesktop>
          </If>
        </LayoutCol>
        <If condition={showContinueButton}>
          <LayoutCol span={{ mobile: 10, tablet: 0, desktop: 0 }}>
            <FixedMobile>
              <Card>
                <Button
                  title={hasProducts ? 'CONTINUE' : 'NO PRODUCTS'}
                  size='medium'
                  fullWidth
                  loading={loading || isUpdateingItems}
                  disabled={!checkout.canAdvance || !hasProducts}
                  onClick={_handleNext} />
              </Card>
            </FixedMobile>
          </LayoutCol>
        </If>
      </LayoutRow>
    </>

  )
}
